import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import track from "../../components/track"
import iconSunrise from "../../images/icon-circle-sunrise.png"
import iconSilverware from "../../images/icon-circle-silverware.png"
import iconCalendar from "../../images/icon-circle-calendar.png"

const Dosing = () => {

  const pageTitle = 'What Is It and How Does It Work?';
  const pageDescription = 'See how MOVANTIK can treat opioid-induced constipation and how quickly it can help relieve symptoms.';
  const pageName = "Dosing";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="patient" pageSection="dosing" pageClass="dosing"  pageName={pageName}>
      <div className="page-title">
        <div className="container">Dosing</div>
      </div>
      <div className="container">
        <h1>Convenient dosing with 2 options<sup>1</sup></h1>
      </div>
      <div className="headline-divider"></div>
      <div className="container">
      <div className="columns three">
          <div className="column left-column">
            <img src={iconSunrise} className="icon" alt="icon" />
            <p>One tablet, once-daily in the AM</p>
          </div>
          <div className="column middle-column">
            <img src={iconSilverware} className="icon" alt="icon" />
            <p>On an empty stomach (1 hour before breakfast or 2 hours after)</p>
          </div>
          <div className="column right-column">
            <img src={iconCalendar} className="icon" alt="icon" />
            <p>For the duration of opioid treatment</p>
          </div>
        </div>
        <h2>MOVANTIK should be taken exactly as your healthcare provider tells you.</h2>
        <ul>
          <li>2 dosing strengths—25 mg and 12.5 mg—allow for flexibility and convenient once-daily dosing. Take on an empty stomach<sup>1</sup></li>
        </ul>
        {/* <p className="dosing-callout-header">MOVANTIK should be taken exactly as your health care provider tells you.</p>
        <div className="columns two">
          <div className="column left-column">
            <img src={chartDosingThree} className="chart" alt="chart" />
          </div>
          <div className="column right-column">
            <img src={chartDosingFour} className="chart" alt="chart" />
          </div>
        </div> */}

        <div className="center">
          <Link to="/savings/" id="patient-dosing-btn-savings" className="button" onClick={() => track("patient Dosing page", "button click", "DTC About Movantik-Savings Card Button")}>
            <div className="btn-text">Find out if you can save on your prescription<br />with the MOVANTIK <span className="bold">Savings Card</span></div>
            <div className="btn-arrow"></div>
          </Link>
        </div>

        <div className="end-matter">
        <div className="footnotes">
          <div className="references">At least 1 hour before the first meal of the day or 2&nbsp;hours after the meal. Consumption of grapefruit or grapefruit juice during treatment with MOVANTIK is to be avoided.<sup>1</sup></div>
          </div>
          <div className="references"><span className="bold">Reference: 1.</span>&nbsp;MOVANTIK<sup>&reg;</sup> (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023.</div>
        </div>
      </div>
    </Layout>
  )
}

export default Dosing
